import React from 'react'
import ShowGuesser from "@api-platform/admin/lib/ShowGuesser"
import { TextField, ReferenceField, useTranslate, FunctionField } from "react-admin"

function Show(props) {
  const translate = useTranslate();

  return (
    <ShowGuesser {...props}>
      <FunctionField
        label={translate('hpas.id')}
        render={record => {
          return <span>{record.originId}</span>
        }}
      />
      <ReferenceField source="platform" reference="tv_platforms"
                      label={translate('hpas.platform')}
                      sortBy={'platform.name'}>
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField source="communication" reference="tv_communications" link={false}
                      label={translate('hpas.communication')}
                      sortBy={'communication.name'}>
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField source="region" reference="tv_regions" link={false}
                      label={translate('hpas.region')}
                      sortBy={'region.name'}>
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField source="unit" reference="tv_units" link={false}
                      label={translate('hpas.unit')}
                      sortBy={'unit.name'}>
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField source="distribution" reference="tv_distributions" link={false}
          label={translate('hpas.distribution')}>
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField source="direction" reference="tv_directions" link={false}
          label={translate('hpas.direction')}>
        <TextField source="name" />
      </ReferenceField>
    </ShowGuesser>
  )
}

export default Show;