import React from 'react'
import { ShowGuesser} from '@api-platform/admin'
import { useTranslate, TextField, ReferenceField, FunctionField } from 'react-admin';

function Show(props) {
  const translate = useTranslate();
  return (
    <ShowGuesser {...props}>
     {/* <ReferenceField source="role" reference="company_roles" link={false} label={translate('legals.role')}>
        <TextField source="name" />
      </ReferenceField>*/}

      <TextField source="originId" label={translate('id')} sortBy={'id'}/>
      <TextField source="name" label={translate('name')}/>
      <TextField source="site" label={translate('companies.site')}/>
      <TextField source="address" label={translate('companies.address')}/>
      <TextField source="description" label={translate('companies.description')}/>

      <FunctionField label={translate('companies.phones')}
        render={record => record.phones.map(phone => <div key={phone}>{phone}</div>)} />

      <FunctionField label={translate('companies.emails')}
        render={record => record.emails.map(email => <div key={email}>{email}</div>)} />

      <FunctionField label={translate('companies.roles')}
        render={record => record.roles.map(role => (
          <div key={role.code}>{role.name}</div>
        ))} />
    </ShowGuesser>
  )
}

export default Show