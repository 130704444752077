import React from 'react'
import {FieldGuesser, ShowGuesser} from '@api-platform/admin'
import { useTranslate, TextField, ReferenceField, FunctionField } from 'react-admin';
import ReferenceShowField from "../../components/ReferenceShowField";

function Show(props) {
  const translate = useTranslate()

  return (
    <ShowGuesser {...props}>
      <TextField source="originId" label={translate('id')} sortBy={'id'}/>
      <TextField source="name" label={translate('legals.name')}/>
      <FunctionField
        label={translate('legals.company')}
        render={record => {
          return <ReferenceShowField iri={record.company} fieldName="name"/>
        }}
      />

      <FunctionField
        label={translate('legals.tax')}
        render={record => {
          return <ReferenceShowField iri={record.tax} fieldName="name"/>
        }}
      />

      <TextField source="inn" label={translate('legals.inn')}/>
      <TextField source="kpp" label={translate('legals.kpp')}/>
      <TextField source="comment" label={translate('legals.comment')}/>

      <FunctionField
        label={translate('legals.role')}
        render={record => {
          return <ReferenceShowField iri={record.role} fieldName="name"/>
        }}
      />

    </ShowGuesser>
  )
}

export default Show