import React, {useEffect} from 'react'
import {EditGuesser, InputGuesser,} from '@api-platform/admin'
import { useTranslate, ReferenceInput, AutocompleteInput } from 'react-admin';
import useApiFetch from "../../components/Hooks/useApiFetch";
import urls from "../../urls";

const onFailure = (error) => {
  console.log('onFailure');
  console.log(error);
}

function Edit(props) {
  const getCompanyUrl = () => {
    return legal.company
  }

  const translate = useTranslate();
  const [{data: legal}, getLegal] = useApiFetch(props.id)
  const [{data: company}, getCompany] = useApiFetch(getCompanyUrl)
  const [{data: companies}, getCompanies] = useApiFetch(urls.companies)

  useEffect(() => {
    getLegal()
    getCompanies({
      pagination: false
    })
  }, [])

  useEffect(() => {
    if (legal && legal.company) {
      getCompany()
    }
  }, [legal])

  return (
    <EditGuesser
      {...props}
      undoable={false}
      onFailure={onFailure}
    >
      <InputGuesser source="name" label={translate('legals.name')}/>

      {companies && companies.length &&
      <AutocompleteInput source="company"
        choices={companies} optionText="name" optionValue="@id" label={translate('legals.company')}/>
      }

      <InputGuesser source="inn" label={translate('legals.inn')}/>
      <InputGuesser source="kpp" label={translate('legals.kpp')}/>
      <InputGuesser source="comment" label={translate('legals.comment')}/>

      <ReferenceInput
        source="tax"
        reference="taxes"
        label={translate('legals.tax')}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>

      {company.roles &&
        <AutocompleteInput source="role"
          choices={company.roles} optionText="name" optionValue="@id" label={translate('legals.role')}/>
      }
    </EditGuesser>
  )
}

export default Edit