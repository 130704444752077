import React, { useEffect} from 'react'
import useApiFetch from "./Hooks/useApiFetch";


function ReferenceShowField({iri, fieldName}) {
  const [{data: resource}, getResource] = useApiFetch(iri)

  useEffect(() => {
    getResource()
  }, [])

  return <span>{resource[fieldName] ?? ''}</span>
}

export default ReferenceShowField