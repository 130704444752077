import React from 'react'
import { ListGuesser, FieldGuesser } from '@api-platform/admin'
import { useTranslate, TextField, FunctionField, Filter, TextInput } from 'react-admin'
import ListPagination from "../../components/ListPagination"
import options from '../../options'
import PostBulkActionButtons from "../../components/list/PostBulkActionButtons";
import ReferenceShowField from "../../components/ReferenceShowField";

function List(props) {
  const translate = useTranslate();
  return (
    <ListGuesser
      perPage={options.perPage}
      pagination={<ListPagination />}
      {...props}
      filters={<Filter>
        <TextInput label={translate('name')} source="name" alwaysOn/>
        <TextInput label={translate('aliases')} source="aliases.name" alwaysOn/>
        <TextInput label={translate('platforms.url')} source="url" alwaysOn/>
      </Filter>}
      bulkActionButtons={<PostBulkActionButtons showDeleteButton={true}/>}
    >
      <TextField source="originId" label={translate('id')} sortBy={'id'}/>
      <FieldGuesser source="name" label={translate('name')}/>
      <FieldGuesser source="url" label={translate('platforms.url')} sortable={false}/>
      <FunctionField label={translate('aliases')}
        render={record => record.aliases
          ? record.aliases.map(alias => <div key={alias}>{alias}</div>)
          : <div />
        } />
      <FieldGuesser source="withData" sortable={false}/>
      <FunctionField
        label={translate('platforms.pool')}
        render={record => {
          return <ReferenceShowField iri={record.pool} fieldName="name"/>
        }}
      />
      <TextField source="foreignCode" sortable={false} label={translate('foreignCode')}/>
      <FieldGuesser source="correctable" sortable={false}/>
    </ListGuesser>
  )
}

export default List