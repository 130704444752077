import Keycloak from 'keycloak-js';

const initOptions = {
  "realm": process.env.REACT_APP_SSO_CONFIG_REALM,
  "url": process.env.REACT_APP_SSO_CONFIG_URL,
  "ssl-required": process.env.REACT_APP_SSO_CONFIG_SSL_REQUIRED,
  "clientId": process.env.REACT_APP_SSO_CONFIG_CLIENT_ID,
  "public-client": process.env.REACT_APP_SSO_CONFIG_PUBLIC_CLIENT,
  "verify-token-audience": process.env.REACT_APP_SSO_CONFIG_VERIFY_TOKEN_AUDIENCE,
  "use-resource-role-mappings": process.env.REACT_APP_SSO_CONFIG_USE_RESOURCE_ROLE_MAPPINGS,
  "confidential-port": process.env.REACT_APP_SSO_CONFIG_CONFIDENTIAL_PORT
}

const keycloak = new Keycloak( initOptions);

export default keycloak