import ru_translation from "./locales/ru/translation";
import en_translation from "./locales/ru/translation";

const path = process.env.REACT_APP_LANG

const translations = {
  ru: ru_translation,
  en: en_translation
}

export default translations[path]