const urls = {
  companies: '/companies',
  companyRoles: '/company_roles',
  digitalStrategies: '/statistic_digital_strategies',
  internetPlatforms: '/statistic_internet_platforms',
  digitalPlatforms: '/statistic_digital_platforms',
  digitalFaceTypes: '/statistic_digital_face_types',
  contractTypes: '/financial/contract_types'
}

export default urls