import React from 'react'
import { ListGuesser, FieldGuesser } from '@api-platform/admin'
import { useTranslate, ChipField, SingleFieldList, ReferenceArrayField } from 'react-admin'
import ListPagination from "../../components/ListPagination"
import options from '../../options'
import PostBulkActionButtons from "../../components/list/PostBulkActionButtons";

function List(props) {
  const translate = useTranslate();
  return (
    <ListGuesser
      perPage={options.perPage}
      pagination={<ListPagination />}
      {...props}
      bulkActionButtons={<PostBulkActionButtons showDeleteButton={true}/>}
    >
      <FieldGuesser source="firstName" label={translate('managers.firstName')}/>
      <FieldGuesser source="lastName" label={translate('managers.lastName')}/>
      <FieldGuesser source="email" label={translate('managers.email')}/>
      <FieldGuesser source="phone" label={translate('managers.phone')}/>
      <ReferenceArrayField label={translate('managers.companies')} source="companies" reference="companies">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </ListGuesser>
  )
}

export default List
