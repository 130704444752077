import React from 'react'
import {ShowGuesser} from '@api-platform/admin'
import { useTranslate, TextField, FunctionField } from 'react-admin';

function Show(props) {
  const translate = useTranslate();
  return (
    <ShowGuesser {...props}>
      <TextField source="originId" label={translate('id')} sortBy={'id'}/>
      <TextField source="name" label={translate('name')}/>
      <FunctionField label={translate('aliases')}
        render={record => record.aliases
          ? record.aliases.map(alias => <div key={alias}>{alias}</div>)
          : <div />
        } />
    </ShowGuesser>
  )
}

export default Show