import React from 'react'
import { Pagination } from 'react-admin'

const paginationOptions = []

function ListPagination(props) {
  return (
    <Pagination rowsPerPageOptions={paginationOptions} {...props} />
  )
}

export default ListPagination