import React from 'react'
import { BulkExportButton, BulkDeleteButton } from 'react-admin'

function PostBulkActionButtons(props) {
  return (
    <>
      {props.showExportButton &&
        <BulkExportButton {...props} />
      }

      {props.showDeleteButton &&
        <BulkDeleteButton {...props} undoable={false} />
      }
    </>
  )
}

export default PostBulkActionButtons