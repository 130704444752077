import React from 'react'
import {CreateGuesser, InputGuesser} from '@api-platform/admin'
import { useTranslate, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';

function Edit(props) {
  const translate = useTranslate();

  return (
    <CreateGuesser {...props}>
      <InputGuesser source="firstName" label={translate('managers.firstName')}/>
      <InputGuesser source="lastName" label={translate('managers.lastName')}/>
      <InputGuesser source="email" label={translate('managers.email')}/>
      <InputGuesser source="phone" label={translate('managers.phone')}/>
      <ReferenceArrayInput source="companies" reference="client_companies" label={translate('managers.companies')}>
        <AutocompleteArrayInput />
      </ReferenceArrayInput>
    </CreateGuesser>
  )
}

export default Edit
