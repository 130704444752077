import roles from '../roles/roles.json'

export default class Roles {
  static hasAccess(accessName) {
    const userRoles = JSON.parse(localStorage.getItem('roles'))
    
    if (!userRoles || !userRoles.length) {
      return false
    }

    return Roles.isRolesInAccess(accessName, userRoles)
  }

  static isRolesInAccess(access, userRoles) {
    if (!(access in roles)) {
      return false
    }

    const accessRoles = roles[access]

    const intersection = accessRoles.filter(x => userRoles.includes(x));

    return intersection.length > 0
  }
}