import React from 'react'
import { ListGuesser, FieldGuesser } from '@api-platform/admin'
import { useTranslate, TextField, NumberField } from 'react-admin'
import ListPagination from "../../components/ListPagination"
import options from '../../options'
import PostBulkActionButtons from "../../components/list/PostBulkActionButtons";

function List(props) {
  const translate = useTranslate();
  return (
    <ListGuesser
      perPage={options.perPage}
      pagination={<ListPagination />}
      {...props}
      bulkActionButtons={<PostBulkActionButtons showDeleteButton={true}/>}
    >
      <TextField source="originId" label={translate('id')}/>
      <FieldGuesser source="name" label={translate('name')}/>
      <NumberField source="foreignCode" label={'foreignCode'} sortable={false} options={{useGrouping: false}}/>
    </ListGuesser>
  )
}

export default List