import React from 'react';
import {HydraAdmin, ResourceGuesser} from '@api-platform/admin'
import {Resource, useTranslate} from "react-admin";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {apiDocumentationParser, fetchHydra} from "./api/documentParser";

import translationMessages from './translation';

import customDataProvider from "./api/dataProvider";

import DigitalPlatformsList from './resources/DigitalPlatforms/List'
import DigitalPlatformsEdit from './resources/DigitalPlatforms/Edit'
import DigitalPlatformsShow from './resources/DigitalPlatforms/Show'
import DigitalPlatformsCreate from './resources/DigitalPlatforms/Create'

import InternetChannelsList from './resources/InternetChannels/List'
import InternetChannelsEdit from './resources/InternetChannels/Edit'
import InternetChannelsShow from './resources/InternetChannels/Show'
import InternetChannelsCreate from './resources/InternetChannels/Create'

import InternetPlatformsList from './resources/InternetPlatforms/List'
import InternetPlatformsEdit from './resources/InternetPlatforms/Edit'
import InternetPlatformsShow from './resources/InternetPlatforms/Show'
import InternetPlatformsCreate from './resources/InternetPlatforms/Create'

import InternetPlatformClientsList from './resources/InternetPlatformClients/List'
import InternetPlatformClientsEdit from './resources/InternetPlatformClients/Edit'
import InternetPlatformClientsCreate from './resources/InternetPlatformClients/Create'
import InternetPlatformClientsShow from './resources/InternetPlatformClients/Show'

import DigitalFaceFormatsList from './resources/DigitalFaceFormats/List'
import DigitalFaceFormatsEdit from './resources/DigitalFaceFormats/Edit'
import DigitalFaceFormatsShow from './resources/DigitalFaceFormats/Show'
import DigitalFaceFormatsCreate from './resources/DigitalFaceFormats/Create'

import DigitalPlatformClientsList from './resources/DigitalPlatformClients/List'
import DigitalPlatformClientsEdit from './resources/DigitalPlatformClients/Edit'
import DigitalPlatformClientsCreate from './resources/DigitalPlatformClients/Create'
import DigitalPlatformClientsShow from './resources/DigitalPlatformClients/Show'

import DigitalFaceTypesList from './resources/DigitalFaceTypes/List'
import DigitalFaceTypesEdit from './resources/DigitalFaceTypes/Edit'
import DigitalFaceTypesShow from './resources/DigitalFaceTypes/Show'
import DigitalFaceTypesCreate from './resources/DigitalFaceTypes/Create'

import DigitalStrategiesList from './resources/DigitalStrategies/List'
import DigitalStrategiesEdit from './resources/DigitalStrategies/Edit'
import DigitalStrategiesShow from './resources/DigitalStrategies/Show'
import DigitalStrategiesCreate from './resources/DigitalStrategies/Create'

import DigitalTrackingsList from './resources/DigitalTrackings/List'
import DigitalTrackingsEdit from './resources/DigitalTrackings/Edit'
import DigitalTrackingsShow from './resources/DigitalTrackings/Show'
import DigitalTrackingsCreate from './resources/DigitalTrackings/Create'

import DigitalTargetingsList from './resources/DigitalTrackings/List'
import DigitalTargetingsEdit from './resources/DigitalTrackings/Edit'
import DigitalTargetingsShow from './resources/DigitalTrackings/Show'
import DigitalTargetingsCreate from './resources/DigitalTrackings/Create'

import CompaniesList from './resources/Companies/List'
import CompaniesEdit from './resources/Companies/Edit'
import CompaniesShow from './resources/Companies/Show'
import CompaniesCreate from './resources/Companies/Create'

import PoolsList from './resources/Pools/List'
import PoolsEdit from './resources/Pools/Edit'
import PoolsShow from './resources/Pools/Show'
import PoolsCreate from './resources/Pools/Create'

import CompanyManagersList from './resources/CompanyManagers/List'
import CompanyManagersEdit from './resources/CompanyManagers/Edit'
import CompanyManagersCreate from './resources/CompanyManagers/Create'
import CompanyManagersShow from './resources/CompanyManagers/Show'

import LegalsList from './resources/Legals/List'
import LegalsEdit from './resources/Legals/Edit'
import LegalsShow from './resources/Legals/Show'
import LegalsCreate from './resources/Legals/Create'

import PropertiesList from './resources/Properties/List'
import PropertiesEdit from './resources/Properties/Edit'
import PropertiesCreate from './resources/Properties/Create'
import PropertiesShow from './resources/Properties/Show'

import RatioList from './resources/DigitalRatios/List'
import RatioEdit from './resources/DigitalRatios/Edit'
import RatioCreate from './resources/DigitalRatios/Create'
import RatioShow from './resources/DigitalRatios/Show'

import DigitalUnitsList from './resources/DigitalUnits/List'
import DigitalUnitsEdit from './resources/DigitalUnits/Edit'
import DigitalUnitsCreate from './resources/DigitalUnits/Create'
import DigitalUnitsShow from './resources/DigitalUnits/Show'

import AdvertTypesList from './resources/AdvertTypes/List'
import AdvertTypesEdit from './resources/AdvertTypes/Edit'
import AdvertTypesCreate from './resources/AdvertTypes/Create'
import AdvertTypesShow from './resources/AdvertTypes/Show'

import DigitalSourceList from './resources/DigitalSources/List'
import DigitalSourceEdit from './resources/DigitalSources/Edit'
import DigitalSourceCreate from './resources/DigitalSources/Create'
import DigitalSourceShow from './resources/DigitalSources/Show'

import DigitalDataPropertiesList from './resources/DigitalDataProperties/List'
import DigitalDataPropertiesEdit from './resources/DigitalDataProperties/Edit'
import DigitalDataPropertiesCreate from './resources/DigitalDataProperties/Create'
import DigitalDataPropertiesShow from './resources/DigitalDataProperties/Show'

import DigitalDataSourcesList from './resources/DigitalDataSources/List'
import DigitalDataSourcesEdit from './resources/DigitalDataSources/Edit'
import DigitalDataSourcesCreate from './resources/DigitalDataSources/Create'
import DigitalDataSourcesShow from './resources/DigitalDataSources/Show'

import DigitalDepartmentsList from './resources/ListWithName/List'
import DigitalDepartmentsEdit from './resources/ListWithName/Edit'
import DigitalDepartmentsCreate from './resources/ListWithName/Create'
import DigitalDepartmentsShow from './resources/ListWithName/Show'

import DigitalMediaList from './resources/ListWithName/List'
import DigitalMediaEdit from './resources/ListWithName/Edit'
import DigitalMediaCreate from './resources/ListWithName/Create'
import DigitalMediaShow from './resources/ListWithName/Show'

import DigitalFactorsList from './resources/DigitalFactors/List'
import DigitalFactorsEdit from './resources/DigitalFactors/Edit'
import DigitalFactorsCreate from './resources/DigitalFactors/Create'
import DigitalFactorsShow from './resources/DigitalFactors/Show'

import TvCommunicationsList from './resources/TvCommunications/List'
import TvCommunicationsEdit from './resources/TvCommunications/Edit'
import TvCommunicationsCreate from './resources/TvCommunications/Create'
import TvCommunicationsShow from './resources/TvCommunications/Show'

import TvPlatformClientsList from './resources/TvPlatformClients/List'
import TvPlatformClientsEdit from './resources/TvPlatformClients/Edit'
import TvPlatformClientsCreate from './resources/TvPlatformClients/Create'
import TvPlatformClientsShow from './resources/TvPlatformClients/Show'

import TvPlatformsList from './resources/TvPlatforms/List'
import TvPlatformsEdit from './resources/TvPlatforms/Edit'
import TvPlatformsShow from './resources/TvPlatforms/Show'
import TvPlatformsCreate from './resources/TvPlatforms/Create'

import TvUnitsList from './resources/TvUnits/List'
import TvUnitsEdit from './resources/TvUnits/Edit'
import TvUnitsCreate from './resources/TvUnits/Create'
import TvUnitsShow from './resources/TvUnits/Show'

import TvRegionsList from './resources/TvRegions/List'
import TvRegionsEdit from './resources/TvRegions/Edit'
import TvRegionsCreate from './resources/TvRegions/Create'
import TvRegionsShow from './resources/TvRegions/Show'

import TvHpasList from './resources/TvHpas/List'
import TvHpasEdit from './resources/TvHpas/Edit'
import TvHpasCreate from './resources/TvHpas/Create'
import TvHpasShow from './resources/TvHpas/Show'

import TvDirectionsList from './resources/ListWithName/List'
import TvDirectionsEdit from './resources/ListWithName/Edit'
import TvDirectionsCreate from './resources/ListWithName/Create'
import TvDirectionsShow from './resources/ListWithName/Show'

import TvDistributionList from './resources/ListWithName/List'
import TvDistributionEdit from './resources/ListWithName/Edit'
import TvDistributionCreate from './resources/ListWithName/Create'
import TvDistributionShow from './resources/ListWithName/Show'

import GoalsList from './resources/Goals/List'
import GoalsEdit from './resources/Goals/Edit'
import GoalsCreate from './resources/Goals/Create'
import GoalsShow from './resources/Goals/Show'

import AgencyPositionsList from './resources/AgencyPositionsAndDepartments/List'
import AgencyPositionsEdit from './resources/AgencyPositionsAndDepartments/Edit'
import AgencyPositionsCreate from './resources/AgencyPositionsAndDepartments/Create'
import AgencyPositionsShow from './resources/AgencyPositionsAndDepartments/Show'

import AgencyDepartmentsList from './resources/AgencyPositionsAndDepartments/List'
import AgencyDepartmentsEdit from './resources/AgencyPositionsAndDepartments/Edit'
import AgencyDepartmentsCreate from './resources/AgencyPositionsAndDepartments/Create'
import AgencyDepartmentsShow from './resources/AgencyPositionsAndDepartments/Show'

import AgencyManagersList from './resources/AgencyManagers/List'
import AgencyManagersEdit from './resources/AgencyManagers/Edit'
import AgencyManagersCreate from './resources/AgencyManagers/Create'
import AgencyManagersShow from './resources/AgencyManagers/Show'

import {ToastContainer} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import AdminLayout from "./components/layouts/AdminLayout";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import useAuthProvider from "./authProvider";

const onToken = () => {
  if (keycloak.token && keycloak.refreshToken) {
    localStorage.setItem("token", keycloak.token);
    localStorage.setItem("refresh-token", keycloak.refreshToken);
  }
};

const onTokenExpired = () => {
  keycloak
    .updateToken(30)
    .then(() => {
      localStorage.setItem("token", keycloak.token);
      localStorage.setItem("refresh-token", keycloak.refreshToken);
    })
    .catch(() => {
      console.error("failed to refresh token");
    });
};

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const lang = process.env.REACT_APP_LANG;

const dataProvider = customDataProvider(entrypoint, fetchHydra, apiDocumentationParser, true);

const i18nProvider = polyglotI18nProvider(() => translationMessages, lang);

const keycloakOptions = {
  onLoad: 'login-required'
}

const AdminWithKeycloak = () => {
  const authProvider = useAuthProvider()
  const translate = useTranslate();

  return (
    <HydraAdmin
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      entrypoint={entrypoint}
      layout={AdminLayout}
    >
      <ResourceGuesser
        name="companies"
        list={CompaniesList}
        create={CompaniesCreate}
        edit={CompaniesEdit}
        show={CompaniesShow}
        options={{
          label: translate('menu.companies'),
          group: 'contractors'
        }}
      />

      <ResourceGuesser
        name="legals"
        list={LegalsList}
        create={LegalsCreate}
        edit={LegalsEdit}
        show={LegalsShow}
        options={{
          label: translate('menu.legals'),
          group: 'contractors'
        }}
      />

      <ResourceGuesser
        name="pools"
        list={PoolsList}
        create={PoolsCreate}
        edit={PoolsEdit}
        show={PoolsShow}
        options={{
          label: translate('menu.pools'),
          group: 'contractors'
        }}
      />

      <ResourceGuesser
        name="company/company_managers"
        list={CompanyManagersList}
        create={CompanyManagersCreate}
        edit={CompanyManagersEdit}
        show={CompanyManagersShow}
        options={{
          label: translate('menu.company_managers'),
          group: 'contractors'
        }}
      />

      <ResourceGuesser
        name="advert_types"
        list={AdvertTypesList}
        create={AdvertTypesCreate}
        edit={AdvertTypesEdit}
        show={AdvertTypesShow}
        options={{
          label: translate('menu.advert_types')
        }}
      />

      <ResourceGuesser
        name="digital_platforms"
        list={DigitalPlatformsList}
        create={DigitalPlatformsCreate}
        edit={DigitalPlatformsEdit}
        show={DigitalPlatformsShow}
        options={{
          label: translate('menu.digital_platforms'),
          group: 'digital'
        }}
      />

      <ResourceGuesser
        name="digital_platform_pools"
        list={PoolsList}
        create={PoolsCreate}
        edit={PoolsEdit}
        show={PoolsShow}
        options={{
          label: translate('menu.digital_platform_pools'),
          group: 'digital'
        }}
      />

      <ResourceGuesser
        name="digital_face_formats"
        list={DigitalFaceFormatsList}
        create={DigitalFaceFormatsCreate}
        edit={DigitalFaceFormatsEdit}
        show={DigitalFaceFormatsShow}
        options={{
          label: translate('menu.face_formats'),
          group: 'digital'
        }}
      />

      <ResourceGuesser
        name="digital_platform_clients"
        list={DigitalPlatformClientsList}
        create={DigitalPlatformClientsCreate}
        edit={DigitalPlatformClientsEdit}
        show={DigitalPlatformClientsShow}
        options={{
          label: translate('menu.platform_clients'),
          group: 'digital'
        }}
      />

      <ResourceGuesser
        name="digital_face_types"
        list={DigitalFaceTypesList}
        create={DigitalFaceTypesCreate}
        edit={DigitalFaceTypesEdit}
        show={DigitalFaceTypesShow}
        options={{
          label: translate('menu.digital_face_types'),
          group: 'digital'
        }}
      />

      <ResourceGuesser
        name="digital_strategies"
        list={DigitalStrategiesList}
        create={DigitalStrategiesCreate}
        edit={DigitalStrategiesEdit}
        show={DigitalStrategiesShow}
        options={{
          label: translate('menu.digital_strategies'),
          group: 'digital'
        }}
      />

      <ResourceGuesser
        name="digital_properties"
        list={PropertiesList}
        create={PropertiesCreate}
        edit={PropertiesEdit}
        show={PropertiesShow}
        options={{
          label: translate('menu.digital_properties'),
          group: 'digital'
        }}
      />

      <ResourceGuesser
        name="digital_ratios"
        list={RatioList}
        create={RatioCreate}
        edit={RatioEdit}
        show={RatioShow}
        options={{
          label: translate('menu.ratio'),
          group: 'digital'
        }}
      />

      <ResourceGuesser
        name="digital_units"
        list={DigitalUnitsList}
        create={DigitalUnitsCreate}
        edit={DigitalUnitsEdit}
        show={DigitalUnitsShow}
        options={{
          label: translate('menu.digital_units'),
          group: 'digital'
        }}
      />

      <ResourceGuesser
        name="digital_channels"
        list={DigitalSourceList}
        create={DigitalSourceCreate}
        edit={DigitalSourceEdit}
        show={DigitalSourceShow}
        options={{
          label: translate('menu.digital_channels'),
          group: 'digital'
        }}
      />

      <ResourceGuesser
        name="digital_sources"
        list={DigitalSourceList}
        create={DigitalSourceCreate}
        edit={DigitalSourceEdit}
        show={DigitalSourceShow}
        options={{
          label: translate('menu.digital_sources'),
          group: 'digital'
        }}
      />

      <ResourceGuesser
        name="digital_dataproperties"
        list={DigitalDataPropertiesList}
        create={DigitalDataPropertiesCreate}
        edit={DigitalDataPropertiesEdit}
        show={DigitalDataPropertiesShow}
        options={{
          label: translate('menu.digital_dataproperties'),
          group: 'digital'
        }}
      />

      <ResourceGuesser
        name="digital_datasources"
        list={DigitalDataSourcesList}
        create={DigitalDataSourcesCreate}
        edit={DigitalDataSourcesEdit}
        show={DigitalDataSourcesShow}
        options={{
          label: translate('menu.digital_datapsources'),
          group: 'digital'
        }}
      />

      <ResourceGuesser
        name="digital_departments"
        list={DigitalDepartmentsList}
        create={DigitalDepartmentsCreate}
        edit={DigitalDepartmentsEdit}
        show={DigitalDepartmentsShow}
        options={{
          label: translate('menu.digital_departments'),
          group: 'digital'
        }}
      />

      <ResourceGuesser
        name="digital_media"
        list={DigitalMediaList}
        create={DigitalMediaCreate}
        edit={DigitalMediaEdit}
        show={DigitalMediaShow}
        options={{
          label: translate('menu.digital_media'),
          group: 'digital'
        }}
      />

      <ResourceGuesser
        name="digital_factors"
        list={DigitalFactorsList}
        create={DigitalFactorsCreate}
        edit={DigitalFactorsEdit}
        show={DigitalFactorsShow}
        options={{
          label: translate('menu.digital_factors'),
          group: 'digital'
        }}
      />

      <ResourceGuesser
        name="digital_trackings"
        list={DigitalTrackingsList}
        create={DigitalTrackingsCreate}
        edit={DigitalTrackingsEdit}
        show={DigitalTrackingsShow}
        options={{
          label: translate('menu.digital_trackings'),
          group: 'digital'
        }}
      />

      <ResourceGuesser
        name="digital_targetings"
        list={DigitalTargetingsList}
        create={DigitalTargetingsCreate}
        edit={DigitalTargetingsEdit}
        show={DigitalTargetingsShow}
        options={{
          label: translate('menu.digital_targetings'),
          group: 'digital'
        }}
      />

      <ResourceGuesser
        name="internet_platforms"
        list={InternetPlatformsList}
        create={InternetPlatformsCreate}
        edit={InternetPlatformsEdit}
        show={InternetPlatformsShow}
        options={{
          label: translate('menu.internet_platforms'),
          group: 'internet'
        }}
      />

      <ResourceGuesser
        name="internet_platform_contractors"
        list={InternetPlatformClientsList}
        create={InternetPlatformClientsCreate}
        edit={InternetPlatformClientsEdit}
        show={InternetPlatformClientsShow}
        options={{
          label: translate('menu.platform_clients'),
          group: 'internet'
        }}
      />

      <ResourceGuesser
        name="internet_properties"
        list={PropertiesList}
        create={PropertiesCreate}
        edit={PropertiesEdit}
        show={PropertiesShow}
        options={{
          label: translate('menu.internet_properties'),
          group: 'internet'
        }}
      />

      <ResourceGuesser
        name="internet_channels"
        list={InternetChannelsList}
        create={InternetChannelsCreate}
        edit={InternetChannelsEdit}
        show={InternetChannelsShow}
        options={{
          label: translate('menu.internet_channels'),
          group: 'internet'
        }}
      />

      <ResourceGuesser
        name="internet_goals"
        list={GoalsList}
        create={GoalsCreate}
        edit={GoalsEdit}
        show={GoalsShow}
        options={{
          label: translate('menu.internet_goals'),
          group: 'internet'
        }}
      />

      <ResourceGuesser
        name="tv_platforms"
        list={TvPlatformsList}
        create={TvPlatformsCreate}
        edit={TvPlatformsEdit}
        show={TvPlatformsShow}
        options={{
          label: translate('menu.tv_platforms'),
          group: 'tv'
        }}
      />

      <ResourceGuesser
        name="tv_communications"
        list={TvCommunicationsList}
        create={TvCommunicationsCreate}
        edit={TvCommunicationsEdit}
        show={TvCommunicationsShow}
        options={{
          label: translate('menu.tv_communications'),
          group: 'tv'
        }}
      />

      <ResourceGuesser
        name="tv_platform_clients"
        list={TvPlatformClientsList}
        create={TvPlatformClientsCreate}
        edit={TvPlatformClientsEdit}
        show={TvPlatformClientsShow}
        options={{
          label: translate('menu.tv_platform_clients'),
          group: 'tv'
        }}
      />

      <ResourceGuesser
        name="tv_hpas"
        list={TvHpasList}
        create={TvHpasCreate}
        edit={TvHpasEdit}
        show={TvHpasShow}
        options={{
          label: translate('menu.hpas'),
          group: 'tv'
        }}
      />

      <ResourceGuesser
        name="tv_properties"
        list={PropertiesList}
        create={PropertiesCreate}
        edit={PropertiesEdit}
        show={PropertiesShow}
        options={{
          label: translate('menu.tv_properties'),
          group: 'tv'
        }}
      />

      <ResourceGuesser
        name="tv_ratios"
        list={RatioList}
        create={RatioCreate}
        edit={RatioEdit}
        show={RatioShow}
        options={{
          label: translate('menu.tv_ratios'),
          group: 'tv'
        }}
      />

      <ResourceGuesser
        name="tv_units"
        list={TvUnitsList}
        create={TvUnitsCreate}
        edit={TvUnitsEdit}
        show={TvUnitsShow}
        options={{
          label: translate('menu.tv_units'),
          group: 'tv'
        }}
      />

      <ResourceGuesser
        name="tv_regions"
        list={TvRegionsList}
        create={TvRegionsCreate}
        edit={TvRegionsEdit}
        show={TvRegionsShow}
        options={{
          label: translate('menu.tv_regions'),
          group: 'tv'
        }}
      />

      <ResourceGuesser
        name="tv_distributions"
        list={TvDistributionList}
        create={TvDistributionCreate}
        edit={TvDistributionEdit}
        show={TvDistributionShow}
        options={{
          label: translate('menu.tv_distributions'),
          group: 'tv'
        }}
      />

      <ResourceGuesser
        name="tv_directions"
        list={TvDirectionsList}
        create={TvDirectionsCreate}
        edit={TvDirectionsEdit}
        show={TvDirectionsShow}
        options={{
          label: translate('menu.tv_directions'),
          group: 'tv'
        }}
      />

      <ResourceGuesser
        name="agency/positions"
        list={AgencyPositionsList}
        create={AgencyPositionsCreate}
        edit={AgencyPositionsEdit}
        show={AgencyPositionsShow}
        options={{
          label: translate('menu.agency_positions'),
          group: 'agency'
        }}
      />

      <ResourceGuesser
        name="agency/departments"
        list={AgencyDepartmentsList}
        create={AgencyDepartmentsCreate}
        edit={AgencyDepartmentsEdit}
        show={AgencyDepartmentsShow}
        options={{
          label: translate('menu.agency_departments'),
          group: 'agency'
        }}
      />

      <ResourceGuesser
        name="agency/managers"
        list={AgencyManagersList}
        create={AgencyManagersCreate}
        edit={AgencyManagersEdit}
        show={AgencyManagersShow}
        options={{
          label: translate('menu.agency_managers'),
          group: 'agency'
        }}
      />

      <Resource name="taxes"/>
      <Resource name="company_roles"/>
      <Resource name="client_companies"/>
      <ToastContainer />
    </HydraAdmin>
  );
};

function App() {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      LoadingComponent={<div>Loading...</div>}
      initOptions={keycloakOptions}
      onTokens={onToken}
      onTokenExpired={onTokenExpired}
    >
      <AdminWithKeycloak />
    </ReactKeycloakProvider>
  )
}

export default App;
