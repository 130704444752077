import React from 'react';
import { CreateGuesser, InputGuesser } from '@api-platform/admin';
import { useTranslate, ArrayInput, SimpleFormIterator, TextInput } from 'react-admin';

function Create(props) {
  const translate = useTranslate();

  return (
    <CreateGuesser {...props} undoable={false}>
      <InputGuesser source="name" label={translate('formats.name')}/>
      <ArrayInput source="aliases" label={translate('aliases')}>
        <SimpleFormIterator>
          <TextInput label={translate('alias')}/>
        </SimpleFormIterator>
      </ArrayInput>
      <InputGuesser source="comment" label={translate('formats.comment')}/>
    </CreateGuesser>
  )
}

export default Create;