import React, {useEffect} from 'react'
import {EditGuesser, FieldGuesser, InputGuesser} from '@api-platform/admin'
import { useTranslate, AutocompleteInput, ArrayInput, SimpleFormIterator, TextInput, ReferenceInput } from 'react-admin';
import useApiFetch from "../../components/Hooks/useApiFetch";
import urls from "../../urls";

function Edit(props) {
  const translate = useTranslate();
  const [{data: foreigns}, getForeigns] = useApiFetch(urls.digitalPlatforms)

  useEffect(() => {
    getForeigns()
  }, [])

  return (
    <EditGuesser {...props} undoable={false}>
      <InputGuesser source="name" label={translate('name')}/>
      <InputGuesser source="url" label={translate('platforms.url')}/>
      <ArrayInput source="aliases" label={translate('aliases')}>
        <SimpleFormIterator>
          <TextInput label={translate('alias')}/>
        </SimpleFormIterator>
      </ArrayInput>
      <InputGuesser source="withData" />
      <ReferenceInput
        source="pool"
        reference="digital_platform_pools"
        label={translate('platforms.pool')}
        allowEmpty={true}
        emptyText={translate('emptyText')}
        filterToQuery={searchText => ({name: searchText})}
        format={v =>( v != null ? v['@id'] || v : null)}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
        <AutocompleteInput optionText="name" optionValue="id" choices={foreigns} source='foreignCode'
          label={translate('foreignCode')} suggestionLimit={50} />
      <InputGuesser source="correctable" />
    </EditGuesser>
  )
}

export default Edit
