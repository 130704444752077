import React, {useEffect} from 'react'
import { CreateGuesser } from '@api-platform/admin'
import { useTranslate, TextInput, ArrayInput, SimpleFormIterator, AutocompleteArrayInput, FunctionField,
  SelectInput, ReferenceInput, AutocompleteInput} from 'react-admin';
import useApiFetch from "../../components/Hooks/useApiFetch";
import urls from "../../urls";

function Create(props) {
  const translate = useTranslate();
  const [{data: roles}, getRoles] = useApiFetch('/company_roles')
  const [{data: contractTypes}, getContractTypes] = useApiFetch(urls.contractTypes)

  useEffect(() => {
    getRoles()
    getContractTypes()
  }, [])

  return (
    <CreateGuesser {...props}>
      <TextInput source="name" label={translate('name')}/>
      <TextInput source="site" label={translate('companies.site')}/>
      <TextInput source="address" label={translate('companies.address')}/>
      <TextInput source="description" label={translate('companies.description')}/>

      <ArrayInput source="phones" label={translate('companies.phones')}>
        <SimpleFormIterator>
          <TextInput label={translate('companies.phone')}/>
        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput source="emails" label={translate('companies.emails')}>
        <SimpleFormIterator>
          <TextInput label={translate('companies.email')}/>
        </SimpleFormIterator>
      </ArrayInput>

      <AutocompleteArrayInput source="roles"
        choices={roles} optionText="name" optionValue="@id" label={translate('companies.roles')}/>

      <FunctionField addLabel={false}
        render={record => {
          return <SelectInput source="contractType"
            choices={contractTypes} optionText="name" optionValue="@id" label={translate('companies.contractType')}/>
        }
        } />

      <ReferenceInput
        source="pool"
        reference="pools"
        label={translate('companies.pool')}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </CreateGuesser>
  )
}

export default Create