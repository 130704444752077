import React from 'react'
import {CreateGuesser, InputGuesser} from '@api-platform/admin'
import { useTranslate, TextInput, ArrayInput, SimpleFormIterator, } from 'react-admin';

function Edit(props) {
  const translate = useTranslate();

  return (
    <CreateGuesser {...props}>
      <InputGuesser source="name" label={translate('name')}/>
      <ArrayInput source="aliases" label={translate('aliases')}>
        <SimpleFormIterator>
          <TextInput label={translate('alias')}/>
        </SimpleFormIterator>
      </ArrayInput>
    </CreateGuesser>
  )
}

export default Edit