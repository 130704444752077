import React from 'react'
import {FieldGuesser, ListGuesser, ShowGuesser} from '@api-platform/admin'
import { useTranslate, TextField, FunctionField, BooleanField } from 'react-admin';

function Show(props) {
  const translate = useTranslate();
  return (
    <ShowGuesser {...props}>
      <TextField source="originId" label={translate('id')} sortBy={'id'}/>
      <TextField source="name" label={translate('properties.name')}/>
      <TextField source="nameFact" label={translate('properties.nameFact')}/>
      <FunctionField label={translate('properties.type')}
        render={record => translate(`properties.${record.type}`)}
      />
      <TextField source="code" label={translate('properties.code')}/>
      <BooleanField source="required" label={translate('properties.required')}/>
      <TextField source="sort" label={translate('properties.sort')}/>
      <TextField source="foreignCode" label={translate('foreignCode')}/>
      <FunctionField label={translate('properties.entity')}
        render={record => translate(`properties.${record.entity}`)}
      />
      <FunctionField label={translate('properties.method')}
        render={record => translate(`properties.${record.method}`)}
      />
    </ShowGuesser>
  )
}

export default Show