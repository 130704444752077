import * as React from 'react';
import {useSelector} from 'react-redux';
import { useLocation } from 'react-router-dom'
import {useTranslate, DashboardMenuItem, MenuItemLink, getResources} from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import BrandingWatermarkOutlinedIcon from '@material-ui/icons/BrandingWatermarkOutlined';

const Menu = (props) => {
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const translate = useTranslate();
  const location = useLocation();

  const [groupsStatuses, setGroupsStatuses] = React.useState({});

  const filteredResources = resources.filter(item => item.hasList)

  const groupNames = {
    internet: 'Perfomance',
    digital: 'Digital',
    tv: 'TV',
    agency: 'Agency',
    contractors: translate('menu.contractors'),
  }

  const groupedResources = {
    items: [],
    groups: {},
  }

  for (let resource of filteredResources) {
    if (resource.hasOwnProperty('options') && resource.options && resource.options.group) {
      if (!groupedResources.groups[resource.options.group]) {
        groupedResources.groups[resource.options.group] = []
      }

      if (location.pathname && resource.name && location.pathname.includes(resource.name)) {
        if (!groupsStatuses.hasOwnProperty(resource.options.group)) {
          setGroupsStatuses({
            ...groupsStatuses,
            [resource.options.group]: true
          })
        }
      }

      groupedResources.groups[resource.options.group].push(resource)
    } else {
      groupedResources.items.push(resource)
    }
  }

  const handleGroupClick = (group) => {
    setGroupsStatuses({
      ...groupsStatuses,
      [group]: !groupsStatuses[group]
    })
  };

  const getGroupName = group => groupNames.hasOwnProperty(group) ? groupNames[group] : ''

  return (
    <div>
      <DashboardMenuItem onClick={props.onMenuClick} sidebarIsOpen={open} />

      {groupedResources && groupedResources.items && groupedResources.items.map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            (resource.options && translate(resource.options.label)) ||
            resource.name
          }
          leftIcon={
            resource.icon ? <resource.icon /> : <DefaultIcon />
          }
          onClick={props.onMenuClick}
          sidebarIsOpen={open}
        />
      ))}

      {groupedResources && groupedResources.groups && Object.keys(groupedResources.groups).length > 0 &&
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {Object.keys(groupedResources.groups).map(group => (
          <React.Fragment key={group}>
            <ListItem button onClick={() => handleGroupClick(group)} title={getGroupName(group)}>
              <>
                {open &&
                  <>
                    <div className="MuiListItemIcon-root RaMenuItemLink-icon-34">
                      <BrandingWatermarkOutlinedIcon />
                    </div>
                    <ListItemText primary={getGroupName(group)} />
                  </>
                }

                {groupsStatuses[group] ? <ExpandLess /> : <ExpandMore />}
              </>
            </ListItem>
            <Collapse in={groupsStatuses[group]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {groupedResources.groups[group].map(resource => (
                  <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={
                      (resource.options && translate(resource.options.label)) ||
                      resource.name
                    }
                    leftIcon={
                      resource.icon ? <resource.icon /> : <DefaultIcon />
                    }
                    onClick={props.onMenuClick}
                    sidebarIsOpen={open}
                  />
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
      }
    </div>
  );
}

export default Menu;