import React from 'react'
import { EditGuesser, InputGuesser } from '@api-platform/admin'
import { useTranslate, SelectInput, ArrayInput, SimpleFormIterator, TextInput } from 'react-admin';

function Edit(props) {
  const translate = useTranslate();

  return (
    <EditGuesser {...props} undoable={false}>
      <InputGuesser source="name" label={translate('name')}/>
      <ArrayInput source="aliases" label={translate('aliases')}>
        <SimpleFormIterator>
          <TextInput label={translate('alias')}/>
        </SimpleFormIterator>
      </ArrayInput>
      <InputGuesser source="asPercent" label={translate('ratio.asPercent')}/>
    </EditGuesser>
  )
}

export default Edit