import Roles from "./roles/Roles";
import { useKeycloak } from '@react-keycloak/web'

export const parseJwt = function (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''))

  return JSON.parse(jsonPayload);
};

const useAuthProvider = () => {
  const {keycloak} = useKeycloak()

  return ({
    login: () => {
      console.log('login')
      return keycloak.login()
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
      return keycloak.authenticated &&
      keycloak.token ? Promise.resolve() : Promise.reject("Failed to obtain access token.");
    },
    logout: () => keycloak.logout(),
    getPermissions:() => {
      return Promise.resolve(true);
      let hasRole = false;
      if (keycloak.token) {
        const jwtData = parseJwt(keycloak.token);

        const roles = jwtData.roles
        localStorage.setItem('roles', JSON.stringify(roles));

        if (Roles.hasAccess('AdminAccess')) {
          //window.location.replace('/');
          return Promise.resolve(true);
        } else {
          return Promise.resolve(false);
          //return Promise.reject();
        }

      }
    },
  });
};

export default useAuthProvider;