import React, {useEffect} from 'react'
import {CreateGuesser, FieldGuesser, InputGuesser} from '@api-platform/admin'
import { useTranslate, AutocompleteInput } from 'react-admin';
import useApiFetch from "../../components/Hooks/useApiFetch";
import urls from "../../urls";

function Create(props) {
  const translate = useTranslate();
  const [{data: foreigns}, getForeigns] = useApiFetch(urls.digitalStrategies)

  useEffect(() => {
    getForeigns()
  }, [])

  return (
    <CreateGuesser {...props} undoable={false}>
      <InputGuesser source="name" label={translate('name')} />
      <AutocompleteInput source="foreignCode" choices={foreigns} optionText="name" optionValue="id" label={translate('foreignCode')}/>
    </CreateGuesser>
  )
}

export default Create