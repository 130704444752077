import {useReducer, useCallback} from 'react';
import reducer, {initialState} from '../Api/ReducerFetch'
import {LOADING, SUCCESS, ERROR} from '../Api/actionTypes';
import {api} from "../../utils";

const getUrl = (url, params = {}) => {
  if (typeof params !== 'object') {
    return url
  }

  let urlParams = []

  for (let key of Object.keys(params)) {
    if (Array.isArray(params[key])) {
      for (let value of params[key]) {
        urlParams.push(`${key}=${value}`)
      }
    } else {
      urlParams.push(`${key}=${params[key]}`)
    }
  }

  const urlParamsString = urlParams.join('&')

  const delimiter = url.includes('?')
    ? '&'
    : '?'

  return urlParams.length
    ? `${url}${delimiter}${urlParamsString}`
    : url
}

const useApiFetch = (url, {params = {}} = {}) => {
  const initial = params.initial || initialState
  const [state, dispatch] = useReducer(reducer, initial)

  const makeRequest = useCallback((params, id) => {
    dispatch({type: LOADING})

    try {
      url = (typeof url === 'function') ? url(id) : url

      const apiUrl = getUrl(url, params)

      api.get(apiUrl)
        .then((response) => {
          let data = {}

          if (response['hydra:member']) {
            data.data = response['hydra:member']
          } else {
            data.data = response
          }

          if (response['hydra:totalItems'] !== undefined) {
            data.total = response['hydra:totalItems']
          }

          dispatch({type: SUCCESS, data})
        })
        .catch((error) => {
          dispatch({type: ERROR, data: error})
        })
    } catch (error) {
      dispatch({type: ERROR, data: error})
    }
  }, [url, params]);

  return [state, makeRequest];
};

export default useApiFetch;