import russianMessages from 'ra-language-russian';

const translation = {
  ...russianMessages,
  Unauthorized: "Ошибка авторизации",
  id: "ID",
  name: "Название",
  aliases: "Алиасы",
  alias: "Алиас",
  foreignCode: "Связь со статистикой",
  emptyText: "Не выбрано",

  menu: {
    digital_platforms: "Площадки",
    digital_platform_pools: "Группы Площадок",
    internet_platforms: "Площадки",
    face_formats: "Форматы",
    platform_clients: "Подрядчик-Площадки",
    digital_face_types: "Блок размещения",
    digital_strategies: "Тип размещения",
    legals: "Юридические лица",
    companies: "Компании",
    company_managers: "Менеджеры от клиентов",
    digital_properties: "Метрики",
    internet_properties: "Метрики",
    ratio: "Коэффициенты",
    internet_goals: "Цели",
    advert_types: "Типы рекламных кампаний",
    digital_units: "Единица закупки",
    internet_channels: "Каналы",
    digital_sources: "Sources ID",
    digital_dataproperties: "Data Properties",
    digital_datapsources: "Data Sources",
    digital_channels: "Каналы",
    digital_departments: "Отделы",
    digital_media: "Медиа",
    digital_factors: "Факторы планирования",
    digital_trackings: "Трекинг",
    digital_targetings: "Таргетинг",
    tv_communications: "Каналы коммуникаций",
    tv_platform_clients: "Подрядчик-Площадки",
    hpas: "HPA",
    tv_platforms: "Площадки",
    tv_properties: "Метрики",
    tv_ratios: "Коэффициенты",
    tv_units: "Единица закупки",
    tv_regions: "Регионы",
    tv_distributions: "Блоки размещения",
    tv_directions: "Направление",
    agency_positions: "Должности",
    agency_departments: "Подразделения",
    agency_managers: "Менеджеры",
    pools: "Группы Клиентов",
    contractors: "Контрагенты",
  },

  platforms: {
    url: "Url",
    pool: "Группа площадок",
  },

  formats: {
    comment: "Комментарий",
  },

  platform_client: {
    platform: "Площадка",
    contractor: "Подрядчик",
    client: "Клиент",
    sort: "Сортировка",
  },

  legals: {
    name: "Юридические лица",
    tax: "Налог",
    inn: "ИНН",
    kpp: "КПП",
    comment: "Комментарий",
    role: "Роль",
    company: "Компания",
  },

  companies: {
    site: "Сайт",
    address: "Адрес",
    description: "Описание",
    phones: "Телефоны",
    emails: "Электронные почты",
    phone: "Телефон",
    email: "Электронная почта",
    roles: "Роли",
    role: "Роль",
    contractType: "Вид договора",
    pool: "Группа",
  },

  properties: {
    name: "Название метрики в плане",
    nameFact: "Название метрики в факте",
    code: "Код",
    type: "Тип",
    required: "Обязательное",
    sort: "Сортировка",
    float: "Дробный",
    int: "Целый",
    callback: "Callback класс",
    entity: "Контролируемая область",
    method: "Расчет значения",
    face: "Площадка",
    advertising: "Кампания",
    sum: "Сумма по дням",
    max: "Нарастающий итог",
  },

  ratio: {
    name: "Название",
    id: "ID",
    asPercent: "Как процент",
  },

  managers: {
    firstName: "Имя",
    lastName: "Фамилия",
    id: "ID",
    email: "Email",
    phone: "Телефон",
    position: "Должность",
    department: "Подразделение",
    companies: "Компании",
  },

  hpas: {
    platform: "Площадка",
    communication: "Канал коммуникации",
    region: "Регион",
    unit: "Единица закупки",
    id: "Код",
    distribution: "Блок размещения",
    direction: "Направление",
  },

  trackings: {
    "value": "Значение",
    "values": "Значения"
  },

  errors: {
    "not_to_be_empty": "Поле не должно быть пустым",
    "not_valid": "Неверное значение поля",
    "select_not_to_be_empty": "Не выбрано значение",
    "This field has invalid format": "This field has invalid format",
    "This value is already used": "Такое значение уже используется (%s)",
    "Name and period not unique": "Name and period not unique",
    "invalid fm dates": "invalid fm dates",
    "something_went_wrong": "Что-то пошло не так. Попробуйте обновить страницу",
    "Detailing plan date outside the planning Period": "Дата детализации не совпадает с датой периода",
    "Details budget not equals period": "Бюджет детализаций не совпадает с бюджетом периода (%s - %s)",
    "Details goal not equals period": "Сумма целей детализаций не совпадает с целью периода (%s - %s)",
    "This value should not be blank": "Не введено значение поля (%s)",
    "Roles must not be empty": "Не выбраны роли",
    "This field should not be empty": "Поле не должно быть пустым (%s)",
    "The value you selected is not a valid choice": "Выбрано некорректное значение, выберите другое (%s)",
    "This value should be positive": "Значение поля поля должно быть положительным",
    "Format not supported": "Неподдерживаемый формат файла",
    "Access Denied": "Доступ запрещен",
  }
}

export default translation
