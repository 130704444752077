import React from 'react'
import {ShowGuesser} from '@api-platform/admin'
import { useTranslate, TextField, FunctionField } from 'react-admin';
import ReferenceShowField from "../../components/ReferenceShowField";

function Show(props) {
  const translate = useTranslate();
  return (
    <ShowGuesser {...props}>
      <TextField source="originId" label={translate('id')} sortBy={'id'}/>
      <TextField source="name" label={translate('name')}/>
      <TextField source="url" label={translate('platforms.url')}/>
      <FunctionField label={translate('aliases')}
        render={record => record.aliases
          ? record.aliases.map(alias => <div key={alias}>{alias}</div>)
          : <div />
        } />
      <TextField source="withData" />
      <FunctionField
        label={translate('platforms.pool')}
        render={record => {
          return <ReferenceShowField iri={record.pool} fieldName="name"/>
        }}
      />
      <TextField source="foreignCode"  label={translate('foreignCode')}/>
      <TextField source="correctable" />
    </ShowGuesser>
  )
}

export default Show