import React, {cloneElement} from 'react'
import { useListContext, TopToolbar, CreateButton, ExportButton, sanitizeListRestProps } from 'react-admin';
function ListActions (props) {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      {props.showNewButton &&
        <CreateButton basePath={basePath} />
      }

      {props.showExportButton &&
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          maxResults={maxResults}
        />
      }

    </TopToolbar>
  );
}

export default ListActions