import React, {useEffect, cloneElement, useState} from 'react'
import { useTranslate, SimpleFormIterator, ArrayInput, AutocompleteArrayInput,
  useEditController, EditContextProvider, TextInput, SimpleForm, Toolbar, SaveButton, DeleteButton, SelectInput,
  FunctionField, ReferenceInput, AutocompleteInput} from 'react-admin';
import useApiFetch from "../../components/Hooks/useApiFetch";
import urls from "../../urls";

const getTransformedRecord = (data) => {
  if (data && data.roles && data.roles.find(role => role['@id'])) {
    data.roles = data.roles.map(role => role['@id'])
  }

  return data
}

const CompanyEdit = props => {
  const controllerProps = useEditController(props);

  const {
    basePath, // deduced from the location, useful for action buttons
    record, // record fetched via dataProvider.getOne() based on the id from the location
    redirect, // the default redirection route. Defaults to 'list'
    resource, // the resource name, deduced from the location. e.g. 'posts'
    save, // the update callback, to be passed to the underlying form as submit handler
    saving, // boolean that becomes true when the dataProvider is called to update the record
    version, // integer used by the refresh feature,
  } = controllerProps;

  return (
    <EditContextProvider value={controllerProps}>
      {cloneElement(props.children, {
        basePath,
        record: getTransformedRecord(record),
        redirect,
        resource,
        save,
        saving,
        version,
      })}
    </EditContextProvider>
  );
}

const onFailure = (error) => {
  console.log(error);
}

const EditToolbar = props => {
  return (
    <Toolbar {...props}>
      <SaveButton
        submitOnEnter={false}
        redirect={false}
        onfailure={onFailure}
      />
      <DeleteButton undoable={false} />
    </Toolbar>
  )
}

function Edit(props) {
  const controllerProps = useEditController(props);
  const translate = useTranslate();
  const [{data: roles}, getRoles] = useApiFetch(urls.companyRoles)
  const [{data: contractTypes}, getContractTypes] = useApiFetch(urls.contractTypes)
  const [clientRoleCode] = useState('client');
  const [clientCodeIri, setClientCodeIri] = useState(null);

  useEffect(() => {
    if (roles) {
      const clientRole = roles.find(role => role.code === clientRoleCode)

      if (clientRole) {
        setClientCodeIri(clientRole['@id']);
      }
    }
  }, [roles])

  const isClientRole = roles => {
    if (!Array.isArray(roles) || !clientCodeIri) {
      return false
    }

    return roles.some(role => role === clientCodeIri)
  }

  const {
    record
  } = controllerProps;

  useEffect(() => {
    getRoles()
    getContractTypes()
  }, [])

  return (
    <CompanyEdit {...props}
      transform={data => (
        getTransformedRecord(data)
        )}
      undoable={false}
      toolbar={EditToolbar}
    >
      <SimpleForm {...props} toolbar={<EditToolbar {...props}/>}>
        <TextInput source="name" label={translate('name')}/>
        <TextInput source="site" label={translate('companies.site')}/>
        <TextInput source="address" label={translate('companies.address')}/>
        <TextInput source="description" label={translate('companies.description')}/>

        <ArrayInput source="phones" label={translate('companies.phones')}>
          <SimpleFormIterator>
            <TextInput label={translate('companies.phone')}/>
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput source="emails" label={translate('companies.emails')}>
          <SimpleFormIterator>
            <TextInput label={translate('companies.email')}/>
          </SimpleFormIterator>
        </ArrayInput>

        <AutocompleteArrayInput source="roles"
          choices={roles} optionText="name" optionValue="@id" label={translate('companies.roles')}/>

        {record && isClientRole(record.roles) &&
          <>
            <SelectInput source="contractType"
                         choices={contractTypes} optionText="name" optionValue="@id" label={translate('companies.contractType')}/>

            <ReferenceInput
              source="pool"
              reference="pools"
              label={translate('companies.pool')}
              filterToQuery={searchText => ({ name: searchText })}
              format={v =>( v != null ? v['@id'] || v : null)}
            >
              <AutocompleteInput optionValue="@id" />
            </ReferenceInput>
          </>
        }
      </SimpleForm>
    </CompanyEdit>
  )
}

export default Edit
