import React from 'react';
import { EditGuesser } from '@api-platform/admin';
import { ReferenceInput, AutocompleteInput, useTranslate } from "react-admin";

function Edit(props) {
  const translate = useTranslate();

  return (
    <EditGuesser {...props} undoable={false}>
      <ReferenceInput
        source="platform"
        reference="internet_platforms"
        label={translate('platform_client.platform')}
        filterToQuery={searchText => ({ name: searchText })}
        format={v => v['@id'] || v}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>

      <ReferenceInput
        source="contractor"
        reference="legals"
        label={translate('platform_client.contractor')}
        sort={{
          field: 'name', order: 'ASC'
        }}
        filter={{
          'role.code': 'contractor'
        }}
        filterToQuery={searchText => ({ name: searchText })}
        format={v => v['@id'] || v}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </EditGuesser>
  )
}

export default Edit;