import React from 'react'
import {ListGuesser} from '@api-platform/admin'
import { ReferenceField, TextField, useTranslate, Filter, TextInput } from "react-admin"
import ListPagination from "../../components/ListPagination"
import options from '../../options'
import PostBulkActionButtons from "../../components/list/PostBulkActionButtons";

function List(props) {
  const translate = useTranslate();

  return (
    <ListGuesser
      perPage={options.perPage}
      pagination={<ListPagination />}
      {...props}
      filters={<Filter>
        <TextInput label={translate('platform_client.platform')} source="platform.name" />
        <TextInput label={translate('platform_client.contractor')} source="contractor.name" />
      </Filter>}
      bulkActionButtons={<PostBulkActionButtons showDeleteButton={true}/>}
    >

      <ReferenceField source="platform" reference="digital_platforms" label={translate('platform_client.platform')}
        sortBy={'platform.name'}>
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField source="contractor" reference="legals" link={false} label={translate('platform_client.contractor')}
        sortBy={'contractor.name'}>
        <TextField source="name" />
      </ReferenceField>
    </ListGuesser>
  )
}

export default List