import React from 'react';
import { EditGuesser, InputGuesser } from '@api-platform/admin';
import { useTranslate, ArrayInput, SimpleFormIterator, TextInput  } from 'react-admin';

function Edit(props) {
  const translate = useTranslate();

  return (
    <EditGuesser {...props} undoable={false}>
      <InputGuesser source="name" label={translate('formats.name')}/>
      <ArrayInput source="aliases" label={translate('aliases')}>
        <SimpleFormIterator>
          <TextInput label={translate('alias')}/>
        </SimpleFormIterator>
      </ArrayInput>
      <InputGuesser source="comment" label={translate('formats.comment')}/>
    </EditGuesser>
  )
}

export default Edit;