import React from 'react'
import { ListGuesser, FieldGuesser } from '@api-platform/admin'
import { useTranslate, ReferenceField, TextField } from 'react-admin'
import ListPagination from "../../components/ListPagination"
import options from '../../options'
import PostBulkActionButtons from "../../components/list/PostBulkActionButtons";

function List(props) {
  const translate = useTranslate();
  return (
    <ListGuesser
      perPage={options.perPage}
      pagination={<ListPagination />}
      {...props}
      bulkActionButtons={<PostBulkActionButtons showDeleteButton={true}/>}
    >
      <FieldGuesser source="firstName" label={translate('managers.firstName')}/>
      <FieldGuesser source="lastName" label={translate('managers.lastName')}/>
      <FieldGuesser source="email" label={translate('managers.email')}/>
      <FieldGuesser source="phone" label={translate('managers.phone')}/>
      <ReferenceField source="position" reference="agency/positions"
        label={translate('managers.position')}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="department" reference="agency/departments"
        label={translate('managers.department')} >
        <TextField source="name" />
      </ReferenceField>
    </ListGuesser>
  )
}

export default List
