import React from 'react'
import { CreateGuesser, InputGuesser } from '@api-platform/admin'
import { useTranslate,  TextInput } from 'react-admin';


function Create(props) {
  const translate = useTranslate();

  return (
    <CreateGuesser {...props} undoable={false}>
      <TextInput source="originId" label={translate('id')}/>
      <InputGuesser source="name" label={translate('name')}/>
    </CreateGuesser>
  )
}

export default Create