import React, {useEffect} from 'react'
import {CreateGuesser, InputGuesser} from '@api-platform/admin'
import { useTranslate, AutocompleteInput, ReferenceInput } from 'react-admin';
import useApiFetch from "../../components/Hooks/useApiFetch";
import urls from "../../urls";

const onFailure = (error) => {
  console.log('onFailure');
  console.log(error);
}

function Create(props) {
  const translate = useTranslate();

  const [{data: companies}, getCompanies] = useApiFetch(urls.companies)

  useEffect(() => {
    getCompanies({
      pagination: false
    })
  }, [])

  return (
    <CreateGuesser
      {...props}
      undoable={false}
      onFailure={onFailure}
    >
      <InputGuesser source="name" label={translate('legals.name')}/>

      {companies && companies.length &&
      <AutocompleteInput source="company"
        choices={companies} optionText="name" optionValue="@id" label={translate('legals.company')}/>
      }

      <InputGuesser source="inn" label={translate('legals.inn')}/>
      <InputGuesser source="kpp" label={translate('legals.kpp')}/>
      <InputGuesser source="comment" label={translate('legals.comment')}/>

      <ReferenceInput
        source="tax"
        reference="taxes"
        label={translate('legals.tax')}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>

      <ReferenceInput
        source="role"
        reference="company_roles"
        label={translate('legals.role')}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </CreateGuesser>
  )
}

export default Create