import React from 'react'
import {FieldGuesser, ShowGuesser} from '@api-platform/admin'
import { useTranslate, TextField } from 'react-admin';

function Show(props) {
  const translate = useTranslate();
  return (
    <ShowGuesser {...props}>
      <TextField source="originId" label={translate('id')} sortBy={'id'}/>
      <TextField source="name" label={translate('strategies.name')}/>
      <TextField source="foreignCode" label={translate('foreignCode')}/>
    </ShowGuesser>
  )
}

export default Show