import {toast} from "react-toastify";
import sprintf from 'sprintf-js'
import i18next from '../i18n'

const vsprintf = sprintf.vsprintf

const getTranslatedError = (error) => {
  const errorString = error.replace(/\.$/, '')
  if (i18next.exists('errors.' + errorString)) {
    return i18next.t('errors.' + errorString)
  }

  return error
}

const sprintToastError = (errorString) => {
  let error = ''
  let errorArray = errorString.split(' ; ')

  if (errorArray.length <= 1) {
    error = getTranslatedError(errorString)
  } else {
    const message = errorArray.shift().trim().replace(/\./g, "")

    const errorParams = errorArray.map(error => {
      if (i18next.exists('errors.' + error)) {
        return i18next.t('errors.' + error)
      }

      return error
    })

    error = vsprintf(getTranslatedError(message), errorParams)
  }

  toast.error(error)
}

export const sprintToastErrors = (response) => {
  if (response.violations && response.violations.length) {
    for (let violation of response.violations) {
      if (!violation.message) {
        continue
      }

      const error = violation.propertyPath
        ? violation.message + ' ; ' + violation.propertyPath
        : violation.message

      sprintToastError(error)
    }
  } else if (response['hydra:description']) {
    const errors = response['hydra:description'].split(/\r\n|\r|\n/g)

    const parsedErrors = errors.map(error => {
      if (error.includes(':')) {
        const arErrors = error.split(':')
        return arErrors.pop() + ' ; ' + arErrors.pop()
      } else {
        return error
      }
    })

    parsedErrors
      .forEach((error) => {
        sprintToastError(error)
      })
  }
}