import React from 'react'
import ShowGuesser from "@api-platform/admin/lib/ShowGuesser"
import { TextField, ReferenceField, useTranslate } from "react-admin"

function Show(props) {
  const translate = useTranslate();

  return (
    <ShowGuesser {...props}>

      <ReferenceField source="platform" reference="tv_platforms" label={translate('platform_client.platform')}>
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField source="contractor" reference="legals" link={false} label={translate('platform_client.contractor')}>
        <TextField source="name" />
      </ReferenceField>
    </ShowGuesser>
  )
}

export default Show;