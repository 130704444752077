import React from 'react';
import { EditGuesser } from '@api-platform/admin';
import { ReferenceInput, AutocompleteInput, useTranslate, NumberInput } from "react-admin";

function Edit(props) {
  const translate = useTranslate();

  return (
    <EditGuesser {...props} undoable={false}>
      <NumberInput source="id" format={v => {
        return Number.isInteger(v) ? v : Number(v.split('/').pop())
      }} parse={v => {
        return parseInt(v)}} label={translate('hpas.id')}/>

      <ReferenceInput
        source="platform"
        reference="tv_platforms"
        label={translate('hpas.platform')}
        filterToQuery={searchText => ({ name: searchText })}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>

      <ReferenceInput
        source="communication"
        reference="tv_communications"
        label={translate('hpas.communication')}
        filterToQuery={searchText => ({ name: searchText })}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>

      <ReferenceInput
        source="region"
        reference="tv_regions"
        label={translate('hpas.region')}
        filterToQuery={searchText => ({ name: searchText })}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>

      <ReferenceInput
        source="unit"
        reference="tv_units"
        label={translate('hpas.unit')}
        filterToQuery={searchText => ({ name: searchText })}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>

      <ReferenceInput
        source="distribution"
        reference="tv_distributions"
        label={translate('hpas.distribution')}
        filterToQuery={searchText => ({ name: searchText })}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>

      <ReferenceInput
        source="direction"
        reference="tv_directions"
        label={translate('hpas.direction')}
        filterToQuery={searchText => ({ name: searchText })}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </EditGuesser>
  )
}

export default Edit;