import React from 'react'
import {ShowGuesser} from '@api-platform/admin'
import { useTranslate, ReferenceArrayField, TextField, SingleFieldList, ChipField } from 'react-admin';

function Show(props) {
  const translate = useTranslate();
  return (
    <ShowGuesser {...props}>
      <TextField source="firstName" label={translate('managers.firstName')}/>
      <TextField source="lastName" label={translate('managers.lastName')}/>
      <TextField source="email" label={translate('managers.email')}/>
      <TextField source="phone" label={translate('managers.phone')}/>
      <ReferenceArrayField label={translate('managers.companies')} source="companies" reference="companies">
        <SingleFieldList linkType="show">
          <ChipField source="name" label={translate('name')}/>
        </SingleFieldList>
      </ReferenceArrayField>
    </ShowGuesser>
  )
}

export default Show
