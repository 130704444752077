import React, {useEffect} from 'react'
import {CreateGuesser, FieldGuesser, InputGuesser} from '@api-platform/admin'
import { useTranslate, AutocompleteInput, ArrayInput, SimpleFormIterator, TextInput, ReferenceInput } from 'react-admin';
import useApiFetch from "../../components/Hooks/useApiFetch";
import urls from "../../urls";

function Create(props) {
  const translate = useTranslate();
  const [{data: foreigns}, getForeigns] = useApiFetch(urls.digitalPlatforms)

  useEffect(() => {
    getForeigns()
  }, [])

  return (
    <CreateGuesser {...props} undoable={false}>
      <InputGuesser source="name" label={translate('name')} />
      <InputGuesser source="url" label={translate('platforms.url')} />
      <ArrayInput source="aliases" label={translate('aliases')}>
        <SimpleFormIterator>
          <TextInput label={translate('alias')}/>
        </SimpleFormIterator>
      </ArrayInput>
      <InputGuesser source="withData" />
      <ReferenceInput
        source="pool"
        reference="digital_platform_pools"
        label={translate('platforms.pool')}
        allowEmpty={true}
        emptyText={translate('emptyText')}
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
      <AutocompleteInput source="foreignCode" choices={foreigns} optionText="name" optionValue="id"  label={translate('foreignCode')}/>
      <InputGuesser source="correctable" />
    </CreateGuesser>
  )
}

export default Create