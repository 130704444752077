import russianMessages from 'ra-language-russian';

const translation = {
  ...russianMessages,
  Unauthorized: "Authorization error",
  id: "ID",
  name: "Name",
  aliases: "Aliases",
  alias: "Alias",
  foreignCode: "Foreign code",
  emptyText: "Not selected",

  menu: {
    digital_platforms: "Digital platforms",
    digital_platform_pools: "Digital Platform Pools",
    internet_platforms: "Internet platforms",
    face_formats: "Face formats",
    platform_clients: "Digital platform clients",
    digital_face_types: "Face types",
    digital_strategies: "Strategies",
    legals: "Legals",
    companies: "Сompanies",
    company_managers: "Client managers",
    digital_properties: "Digital properties",
    internet_properties: "Internet properties",
    ratio: "Ratio",
    internet_goals: "Goals",
    advert_types: "Advert types",
    digital_units: "Digital units",
    internet_channels: "Internet devices",
    digital_sources: "Digital sources",
    digital_dataproperties: "Data Properties",
    digital_datapsources: "Data Sources",
    digital_channels: "Digital channels",
    digital_departments: "Departments",
    digital_media: "Media",
    digital_factors: "Planning factors",
    digital_trackings: "Trackings",
    digital_targetings: "Targetings",
    tv_communications: "Tv communications",
    tv_platform_clients: "Tv platform clients",
    hpas: "HPA",
    tv_platforms: "Tv platforms",
    tv_properties: "Tv properties",
    tv_ratios: "Tv Ratio",
    tv_units: "Tv units",
    tv_regions: "Tv regions",
    tv_distributions: "Distribution",
    tv_directions: "Directions",
    agency_positions: "Positions",
    agency_departments: "Departments",
    agency_managers: "Managers",
    pools: "Pools",
    contractors: "Contractors",
  },

  platforms: {
    url: "Url",
    pool: "Platforms group",
  },

  formats: {
    comment: "Comment",
  },

  platform_client: {
    platform: "Platform",
    contractor: "Contractor",
    client: "Client",
    sort: "Sort",
  },

  legals: {
    name: "Legals",
    tax: "Tax",
    inn: "INN",
    kpp: "KPP",
    comment: "Comment",
    role: "Role",
    company: "Company",
  },

  companies: {
    site: "Site",
    address: "Address",
    description: "Description",
    phones: "Phones",
    emails: "Emails",
    phone: "Phone",
    email: "Email",
    roles: "Roles",
    role: "Role",
    contractType: "Contract type",
    pool: "Pool",
  },

  properties: {
    name: "Name in plan",
    nameFact: "Name in fact",
    code: "Code",
    type: "Type",
    required: "Required",
    sort: "Sort",
    float: "Float",
    int: "Integer",
    callback: "Callback class",
    entity: "Controlled area",
    method: "Value calculating",
    face: "Platform",
    advertising: "Advertising",
    sum: "Day sum",
    max: "Cumulative total",
  },

  ratio: {
    name: "Name",
    id: "ID",
    asPercent: "As percent",
  },

  managers: {
    firstName: "First Name",
    lastName: "Last Name",
    id: "ID",
    email: "Email",
    phone: "Phone",
    position: "Position",
    department: "Department",
    companies: "Companies",
  },

  hpas: {
    platform: "Platform",
    communication: "Communication",
    region: "Region",
    unit: "Unit",
    id: "Code",
    distribution: "Distribution",
    direction: "Direction",
  },

  trackings: {
    "value": "Value",
    "values": "Values"
  },
}

export default translation
