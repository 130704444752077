import React from 'react'
import { EditGuesser, InputGuesser } from '@api-platform/admin'
import { useTranslate, SelectInput } from 'react-admin';

function Edit(props) {
  const translate = useTranslate();

  return (
    <EditGuesser undoable={false} {...props}>
      <InputGuesser source="name" label={translate('name')}/>
    </EditGuesser>
  )
}

export default Edit