import React from 'react'
import {EditGuesser, InputGuesser} from '@api-platform/admin'
import { useTranslate, AutocompleteArrayInput, ReferenceArrayInput} from 'react-admin';

function Edit(props) {
  const translate = useTranslate();

  return (
    <EditGuesser {...props} undoable={false}>
      <InputGuesser source="firstName" label={translate('managers.firstName')}/>
      <InputGuesser source="lastName" label={translate('managers.lastName')}/>
      <InputGuesser source="email" label={translate('managers.email')}/>
      <InputGuesser source="phone" label={translate('managers.phone')}/>

      <ReferenceArrayInput source="companies" reference="client_companies" label={translate('managers.companies')}>
        <AutocompleteArrayInput />
      </ReferenceArrayInput>

    </EditGuesser>
  )
}

export default Edit
