import React from 'react'
import { EditGuesser, InputGuesser } from '@api-platform/admin'
import { useTranslate, TextInput } from 'react-admin';


function Edit(props) {
  const translate = useTranslate();

  return (
    <EditGuesser {...props} undoable={false}>
      <TextInput source="originId" label={translate('id')}/>
      <InputGuesser source="name" label={translate('name')}/>
    </EditGuesser>
  )
}

export default Edit