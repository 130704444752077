import React from 'react'
import {CreateGuesser, InputGuesser} from '@api-platform/admin'
import { useTranslate } from 'react-admin';

function Edit(props) {
  const translate = useTranslate();

  return (
    <CreateGuesser {...props} undoable={false}>
      <InputGuesser source="name" label={translate('name')}/>
      <InputGuesser source="foreignCode" label={translate('foreignCode')}/>
    </CreateGuesser>
  )
}

export default Edit