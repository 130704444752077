import React from 'react'
import {ShowGuesser} from '@api-platform/admin'
import { useTranslate, TextField, BooleanField, FunctionField } from 'react-admin';

function Show(props) {
  const translate = useTranslate();
  return (
    <ShowGuesser {...props}>
      <TextField source="originId" label={translate('id')}/>
      <TextField source="name" label={translate('name')}/>
      <FunctionField label={translate('trackings.values')}
        render={record => record.values
          ? record.values.map(value => <div key={value}>{value}</div>)
          : <div />
        } />
    </ShowGuesser>
  )
}

export default Show