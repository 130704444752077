import React from 'react'
import {EditGuesser, InputGuesser} from '@api-platform/admin'
import { useTranslate, ReferenceInput, AutocompleteInput } from 'react-admin';

function Edit(props) {
  const translate = useTranslate();

  return (
    <EditGuesser {...props} undoable={false}>
      <InputGuesser source="firstName" label={translate('managers.firstName')}/>
      <InputGuesser source="lastName" label={translate('managers.lastName')}/>
      <InputGuesser source="email" label={translate('managers.email')}/>
      <InputGuesser source="phone" label={translate('managers.phone')}/>
      <ReferenceInput
        source="position"
        reference="agency/positions"
        label={translate('managers.position')}
        filterToQuery={searchText => ({ name: searchText })}
        format={v => v['@id'] || v}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="department"
        reference="agency/departments"
        label={translate('managers.department')}
        filterToQuery={searchText => ({ name: searchText })}
        format={v => v['@id'] || v}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </EditGuesser>
  )
}

export default Edit
