import React from 'react'
import { ListGuesser, FieldGuesser } from '@api-platform/admin'
import { useTranslate, Filter, TextInput, TextField, FunctionField } from 'react-admin'
import ListPagination from "../../components/ListPagination"
import options from '../../options'
import PostBulkActionButtons from "../../components/list/PostBulkActionButtons";
import ListActions from "../../components/list/Actions";

function List(props) {
  const translate = useTranslate();
  return (
    <ListGuesser
      perPage={options.perPage}
      title={translate('menu.companies')}
      pagination={<ListPagination />}
      {...props}
      filters={<Filter>
        <TextInput label={translate('name')} source="name" />
        <TextInput label={translate('companies.role')} source="roles.name" />
      </Filter>}
      bulkActionButtons={<PostBulkActionButtons
        showExportButton={true}
        showDeleteButton={true/*Roles.hasAccess('ClientsEdit')*/}
      />}
      actions={<ListActions
        showNewButton={true/*Roles.hasAccess('ClientsEdit')*/}
        showExportButton={true}
      />}
    >
      <TextField source="originId" label={translate('id')} sortBy={'id'}/>
      <FieldGuesser source="name" label={translate('name')}/>
      <FieldGuesser source="site" label={translate('companies.site')} sortable={false}/>
      <FieldGuesser source="address" label={translate('companies.address')} sortable={false}/>
      <FieldGuesser source="description" label={translate('companies.description')} sortable={false}/>

      <FunctionField label={translate('companies.phones')}
        render={record => record.phones.map(phone => <div key={phone}>{phone}</div>)} />

      <FunctionField label={translate('companies.emails')}
        render={record => record.emails.map(email => <div key={email}>{email}</div>)} />

      <FunctionField label={translate('companies.roles')}
        render={record => record.roles.map(role => (
          <div key={role.code}>{role.name}</div>
        ))} />

    </ListGuesser>
  )
}

export default List