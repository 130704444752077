import React from 'react'
import { ListGuesser, FieldGuesser } from '@api-platform/admin'
import { useTranslate, FunctionField, TextField, NumberField } from 'react-admin'
import ListPagination from "../../components/ListPagination"
import options from '../../options'
import PostBulkActionButtons from "../../components/list/PostBulkActionButtons";

function List(props) {
  const translate = useTranslate();
  return (
    <ListGuesser
      perPage={options.perPage}
      pagination={<ListPagination />}
      {...props}
      bulkActionButtons={<PostBulkActionButtons showDeleteButton={true}/>}
    >
      <TextField source="originId" label={translate('id')} sortBy={'id'}/>
      <FieldGuesser source="name" label={translate('properties.name')}/>
      <FieldGuesser source="nameFact" label={translate('properties.nameFact')}/>
      <FunctionField label={translate('properties.type')}
        render={record => translate(`properties.${record.type}`)}
      />
      <FieldGuesser source="code" label={translate('properties.code')} sortable={false}/>
      <FieldGuesser source="required" label={translate('properties.required')} sortable={false}/>
      {props.resource === 'digital_properties' &&
      <NumberField source="foreignCode" label={'foreignCode'} sortable={false} options={{useGrouping: false}}/>
      }
      <FieldGuesser source="sort" label={translate('properties.sort')} sortable={false}/>
      <FunctionField label={translate('properties.entity')}
        render={record => translate(`properties.${record.entity}`)}
      />
      <FunctionField label={translate('properties.method')}
        render={record => translate(`properties.${record.method}`)}
      />
    </ListGuesser>
  )
}

export default List