import React, {useEffect} from 'react'
import { EditGuesser, InputGuesser} from '@api-platform/admin'
import { useTranslate, AutocompleteInput, ArrayInput, SimpleFormIterator, TextInput } from 'react-admin';
import useApiFetch from "../../components/Hooks/useApiFetch";
import urls from "../../urls";

function Edit(props) {
  const translate = useTranslate();
  const [{data: foreigns}, getForeigns] = useApiFetch(urls.digitalFaceTypes)

  useEffect(() => {
    getForeigns()
  }, [])

  return (
    <EditGuesser {...props} undoable={false}>
      <InputGuesser source="name" label={translate('name')}/>
      <ArrayInput source="aliases" label={translate('aliases')}>
        <SimpleFormIterator>
          <TextInput label={translate('alias')}/>
        </SimpleFormIterator>
      </ArrayInput>
      <AutocompleteInput source="foreignCode" choices={foreigns} optionText="name" optionValue="id" label={translate('foreignCode')}/>
    </EditGuesser>
  )
}

export default Edit