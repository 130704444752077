import React from 'react'
import {FieldGuesser, ShowGuesser} from '@api-platform/admin'
import { useTranslate, ReferenceField, TextField } from 'react-admin';

function Show(props) {
  const translate = useTranslate();
  return (
    <ShowGuesser {...props}>
      <TextField source="firstName" label={translate('managers.firstName')}/>
      <TextField source="lastName" label={translate('managers.lastName')}/>
      <TextField source="email" label={translate('managers.email')}/>
      <TextField source="phone" label={translate('managers.phone')}/>
      <ReferenceField source="position" reference="agency/positions"
                      label={translate('managers.position')}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="department" reference="agency/departments"
                      label={translate('managers.department')} >
        <TextField source="name" />
      </ReferenceField>
    </ShowGuesser>
  )
}

export default Show
