import React from "react";
import {parseHydraDocumentation} from "@api-platform/api-doc-parser";
import baseFetchHydra from "./fetchHydra";
import { Route, Redirect } from 'react-router-dom';

export const fetchHeaders = (method = '') => ({
  Authorization: `Bearer ${window.localStorage.getItem("token")}`,
  ...((method === 'PATCH') && {
    'Content-Type': 'application/merge-patch+json'
  })
});

export const fetchHydra = (url, options = {}) => {
  const method = options.method

  return localStorage.getItem("token")
    ? baseFetchHydra(url, {
      ...options,
      headers: new Headers(fetchHeaders(method)),
    })
    : baseFetchHydra(url, options)
}

export const apiDocumentationParser = (entrypoint) =>
  parseHydraDocumentation(
    entrypoint,
    localStorage.getItem("token")
      ? { headers: new Headers(fetchHeaders()) }
      : {}
  ).then(
    ({ api }) => ({ api }),
    (result) => {
      if (result.status === 401) {
        localStorage.removeItem("token");

        return Promise.resolve({
          api: result.api,
          customRoutes: [
            <Route path="/" render={() => {
              return !localStorage.getItem("token") ? window.location.reload() : <Redirect to="/" />
            }} />
          ],
        });
      }

      return Promise.reject(result);
    },
  );