import React from 'react'
import {ShowGuesser} from '@api-platform/admin'
import {useTranslate, TextField, FunctionField} from 'react-admin';

function Show(props) {
  const translate = useTranslate();
  return (
    <ShowGuesser {...props}>
      <TextField source="originId" label={translate('id')} sortBy={'id'}/>
      <TextField source="name" label={translate('name')}/>
      <FunctionField label={translate('aliases')}
                     render={record => record.aliases
                       ? record.aliases.map(alias => <div key={alias}>{alias}</div>)
                       : <div/>
                     }/>
      <TextField source="withData"/>
      <FunctionField label={'foreignCode'}
                     render={record => record.foreignCodes
                       ? record.foreignCodes.map(foreignCode => <div key={foreignCode.code}>{foreignCode.code}</div>)
                       : <div/>
                     }/>
      <TextField source="correctable"/>
    </ShowGuesser>
  )
}

export default Show