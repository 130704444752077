import React from 'react'
import {ListGuesser} from '@api-platform/admin'
import { ReferenceField, TextField, TextInput, useTranslate, Filter, FunctionField } from "react-admin"
import ListPagination from "../../components/ListPagination"
import options from '../../options'
import PostBulkActionButtons from "../../components/list/PostBulkActionButtons";

function List(props) {
  const translate = useTranslate();

  return (
    <ListGuesser
      perPage={options.perPage}
      pagination={<ListPagination />}
      {...props}
      filters={<Filter>
        <TextInput label={translate('hpas.id')} source="id" alwaysOn />
        <TextInput label={translate('hpas.platform')} source="platform.name" alwaysOn />
        <TextInput label={translate('hpas.communication')} source="communication.name" alwaysOn />
        <TextInput label={translate('hpas.region')} source="region.name" alwaysOn />
        <TextInput label={translate('hpas.unit')} source="unit.name" alwaysOn />
        <TextInput label={translate('hpas.distribution')} source="distribution.name" alwaysOn />
        <TextInput label={translate('hpas.direction')} source="direction.name" alwaysOn />
      </Filter>}
      bulkActionButtons={<PostBulkActionButtons showDeleteButton={true}/>}
    >
      <FunctionField
        label={translate('hpas.id')}
        render={record => {
          return <span>{record.originId}</span>
        }}
      />

      <ReferenceField source="platform" reference="tv_platforms"
                      label={translate('hpas.platform')}
        sortBy={'platform.name'}>
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField source="communication" reference="tv_communications" link={false}
                      label={translate('hpas.communication')}
        sortBy={'communication.name'}>
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField source="region" reference="tv_regions" link={false}
                      label={translate('hpas.region')}
        sortBy={'region.name'}>
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField source="unit" reference="tv_units" link={false}
                      label={translate('hpas.unit')}
        sortBy={'unit.name'}>
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField source="distribution" reference="tv_distributions" link={false}
        label={translate('hpas.distribution')}
        sortBy={'distribution.name'}>
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField source="direction" reference="tv_directions" link={false}
                      label={translate('hpas.direction')}
                      sortBy={'direction.name'}>
        <TextField source="name" />
      </ReferenceField>
    </ListGuesser>
  )
}

export default List