import React from 'react'
import { ListGuesser, FieldGuesser } from '@api-platform/admin'
import { useTranslate, ReferenceField, TextField, Filter, TextInput } from 'react-admin'
import ListPagination from "../../components/ListPagination"
import options from '../../options'
import PostBulkActionButtons from "../../components/list/PostBulkActionButtons";

function List(props) {
  const translate = useTranslate();
  return (
    <ListGuesser
      perPage={options.perPage}
      pagination={<ListPagination />}
      {...props}
      filters={<Filter>
        <TextInput label={translate('legals.company')} source="company.name" />
        <TextInput label={translate('legals.name')} source="name" />
        <TextInput label={translate('legals.role')} source="role.name" />
      </Filter>}
      bulkActionButtons={<PostBulkActionButtons showDeleteButton={true}/>}
    >
      <TextField source="originId" label={translate('id')} sortBy={'id'}/>
      <FieldGuesser source="name" label={translate('legals.name')}/>
      <ReferenceField source="company" reference="companies" link={false} label={translate('legals.company')} sortable={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="tax" reference="taxes" link={false} label={translate('legals.tax')} sortable={false}>
        <TextField source="name" />
      </ReferenceField>
      <FieldGuesser source="inn" label={translate('legals.inn')} sortable={false}/>
      <FieldGuesser source="kpp" label={translate('legals.kpp')} sortable={false}/>
      <FieldGuesser source="comment" label={translate('legals.comment')} sortable={false}/>
      <ReferenceField source="role" reference="company_roles" link={false} label={translate('legals.role')} sortable={false}>
        <TextField source="name" />
      </ReferenceField>
    </ListGuesser>
  )
}

export default List