import keycloak from './keycloak'

const OK_STATUS = 200
const NO_CONTENT_STATUS = 204
const BAD_REQUEST_STATUS = 400
const SERVER_ERROR_STATUS = 500
const UNAUTHORIZED_STATUS = 401

const responseHandler = (response) => {
  if (response.status === UNAUTHORIZED_STATUS) {
    keycloak.logout()
  }

  if (response.status === NO_CONTENT_STATUS) {
    return Promise
      .resolve(true)
  }

  if (
    response.status >= OK_STATUS
    && response.status < BAD_REQUEST_STATUS
  ) {
    return Promise
      .resolve(response.json())
  }

  if (response.status >= SERVER_ERROR_STATUS) {
    return Promise
      .reject('')
  }

  if (
    response.status >= BAD_REQUEST_STATUS
    && response.status < SERVER_ERROR_STATUS
  ) {
    return Promise
      .resolve(response.json())
      .then((responseInJson) => {
        return Promise.reject(responseInJson)
      })
  }
}

export const api = {
  get: function (url) {
    return fetch(process.env.REACT_APP_API_ENTRYPOINT + url, {
      headers: {
        Authorization: ` Bearer ${localStorage.getItem("token")}`
      }
    }).then(responseHandler)
  }
}

