import React from 'react'
import { EditGuesser, InputGuesser } from '@api-platform/admin'
import { useTranslate, SelectInput } from 'react-admin';

function Edit(props) {
  const translate = useTranslate();

  return (
    <EditGuesser {...props} undoable={false}>
      <InputGuesser source="name" label={translate('properties.name')}/>
      <InputGuesser source="nameFact" label={translate('properties.nameFact')}/>
      <SelectInput source="type" choices={[
        { id: 'float', name: translate('properties.float') },
        { id: 'int', name: translate('properties.int') },
      ]} label={translate('properties.type')}/>
      <InputGuesser source="code" label={translate('properties.code')}/>
      <InputGuesser source="callback" label={translate('properties.callback')}/>
      <InputGuesser source="required" label={translate('properties.required')}/>
      <InputGuesser source="sort" label={translate('properties.sort')}/>
      <InputGuesser source="foreignCode" label={translate('foreignCode')}/>
      <SelectInput source="entity" choices={[
        { id: 'face', name: translate('properties.face') },
        { id: 'advertising', name: translate('properties.advertising') },
      ]} label={translate('properties.entity')}/>
      <SelectInput source="method" choices={[
        { id: 'sum', name: translate('properties.sum') },
        { id: 'max', name: translate('properties.max') },
      ]} label={translate('properties.entity')}/>
    </EditGuesser>
  )
}

export default Edit